import React from "react";
import Markdown from "~components/custom/markdown";
import { createSpacingClass } from "~components/utils/spacing";
import withSpacing from "../utils/withSpacingHOC";
import { IText, ITitle } from "~interfaces/components";
import { IStoryBlokComponent } from "~interfaces/storyblock";
import { css } from "@emotion/core";

interface ITextBlockProps extends ITitle, IStoryBlokComponent, IText {
  text_color: string;
  title_custom_text_color: { color: string } | null;
  text_custom_text_color: { color: string } | null;
  open_in_a_new_tab: boolean;
  text_size: string;
  space_between_paragraphs: string;
  boxModel: string;
}

const TextBlock = (props: ITextBlockProps) => {
  const {
    title,
    title_spacing,
    title_size,
    visual_title_size,
    text,
    text_spacing,
    open_in_a_new_tab = false,
    text_size,
    space_between_paragraphs,
    boxModel,
    title_custom_text_color = null,
    text_custom_text_color = null,
  } = props;
  const TitleTag: any = `h${title_size}`;

  const titleSpacingClass = createSpacingClass(title_spacing);
  const titleSizeClass: any = `ef-h${visual_title_size}`;
  const textSpacingClass = createSpacingClass(text_spacing);

  const MarkdownStyles = css`
    & > p {
      font-size: ${text_size}px;
      margin-bottom: ${space_between_paragraphs
        ? space_between_paragraphs + "px"
        : "inherit"};
      ${text_custom_text_color?.color
        ? `color: ${text_custom_text_color.color}`
        : ""}
    }

    & > ul {
      padding-inline-start: 20px;
      color: var(--color-text);
      font-size: ${text_size}px;
      ${text_custom_text_color?.color
        ? `color: ${text_custom_text_color.color}`
        : ""}
    }

    & > * {
      margin: 0;
    }
  `;

  return (
    <div
      className={`${props.text_color === "light" ? "-inv" : ""} ${
        boxModel && boxModel
      }`}
    >
      {title && (
        <TitleTag
          className={`${titleSpacingClass} ${titleSizeClass}`}
          style={{
            ...(title_custom_text_color?.color
              ? {
                  color: title_custom_text_color.color,
                }
              : {}),
          }}
        >
          {title}
        </TitleTag>
      )}
      <Markdown
        className={textSpacingClass}
        source={text}
        linkTarget={(open_in_a_new_tab ? "_blank" : null) as any}
        css={text_size && MarkdownStyles}
        renderers={{
          link: (rprops) => {
            const { href = null }: any = rprops; // href only return the path and not domain.
            const internalLink = href.startsWith('/') || href?.includes("efexchangeyear.") || href?.includes("ef.");
            return (
              <a
                {...rprops}
                rel={internalLink ? null : "noopener noreferrer nofollow"}
              />
            );
          },
        }}
      />
    </div>
  );
};

export default withSpacing(TextBlock);
